/* .header-bar {
  display: none;
} */
.height-full-screen.row {
  height: calc(100vh - 220px);
}
.h-33{height: 33%;}
/* .h-100-v  div, .h-100-v  svg{height: 100%; min-height: 100% !important;} */

.custom-date-trends .react-datepicker-wrapper {
  display: flex !important;
}

.custom-date-trends .hidden-datepicker {
  display: none !important;
}

.custom-date-trends.custom-select-v button+div {
  background: transparent !important;
}
