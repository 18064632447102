h3 {

    font-size: 20px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.sp1 {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 5px;
}

.sp2 {
    font-family: Sailec;
    font-size: 20px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.ind-table th {
    font-weight: 500;
}

.ind-table th {
    font-size: 15px;
}

.ind-table th>div>div>br+span {
    font-size: 13px;
}

.exceptions-table a {
    text-decoration: none;
    display: block;
}

.exceptions-table.tb2 td {
    padding: 7px 2px;
    vertical-align: middle;
}

.scroll-table {
    position: relative;
}

.scroll-table tbody {
    display: block;
    /* max-height: 555px; */
    max-height: calc(100vh - 492px);
    overflow: auto;
}

.scroll-table tbody.h-472 {
    display: block;
    /* max-height: 555px; */
    max-height: calc(100vh - 430px);
    overflow: auto;
}

.scroll-table tbody.h-600 {
    max-height: calc(100vh - 360px) !important;
}

.scroll-table tbody::-webkit-scrollbar {
    width: 5px;
}

.scroll-table tbody::-webkit-scrollbar-track {
    background: #DEEBFD;
    border-radius: 30px;
}

.scroll-table tbody::-webkit-scrollbar-thumb {
    background: #a7c6e4;
    border-radius: 30px;
}

.scroll-table thead,
.scroll-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* even columns width , fix width of table too*/
}

/* .scroll-table thead {
            width: calc( 100% - 1em )
        } */
/* .scroll-table tbody tr:last-child{
            
            position: absolute;
        } */
.tb2 tbody tr.bt {
    border-top: 1px solid #dbdbdb !important
}

/* .h-454{height: 545px;} */
.h-half {
    height: 50%;
    background: #FFF;
}

.border-bottom-5 {
    border-bottom: 0.25rem solid #eeeeee;
}

.border-top-5 {
    border-top: 0.25rem solid #eeeeee
}

.vertical-align-middle {
    vertical-align: middle;
}

.font-15 {
    font-size: 15px !important;
}

.w-auto-col .d-flex.align-items-center {
    width: auto;
}

.tb2 tbody.ftr tr:nth-last-child(1),
.tb2 tbody.ftr tr:nth-last-child(1) td {
    /* width: calc(100% - 4px); */
}

.mt-minus-5 {
    margin-top: -5px !important;
}

.apexcharts-canvas {
    height: 80% !important;
}

.yh .apexcharts-canvas {
    height: 100% !important;
    /* padding-top: 20px !important; */
}

div#chart {
    height: 90% !important;
}

div#chart.height-95vh {
    height: 84% !important;
}

div#chart.yh {
    /* height: 100% !important; */
    padding-top: 20px;
}

.height-80v#chart {
    height: 75% !important;
}

.border-top-5.h-half {
    min-height: auto !important;
}

.h-454 {
    /* min-height: calc(65vh + 16px); */
    min-height: calc(100vh - 310px)
}

.h-454-new {
    /* min-height: calc(65vh + 16px); */
    min-height: calc(100vh - 330px)
}

.apexcharts-yaxis,
.apexcharts-inner {
    padding-top: 40px !important;
}

.h-660 {
    min-height: calc(100vh - 282px);
}

.custom-select {
    width: auto;
    display: inline-block;
    border: 0px !important;
    font: normal normal bold 1.4rem/2rem 'F37 Moon Demi' !important;
    padding: 0 25px 0 18px;
    background-repeat: no-repeat !important;
    background-position: 100% 50% !important;
    background-size: 12px !important;
}

.custom-select option {
    background-color: #191C24 !important;
    color: #fff !important;
    font-size: 16px !important;
    line-height: 2;
}

.form-select:focus {
    box-shadow: none;
}

.min-h-250 {
    min-height: 295px;
}

.active-header .box-h {
    padding: 15px 10px;
}

.cursor-hand {
    cursor: pointer;
}

.modal-dialog .scroll-table tbody {
    /* max-height: calc(100vh - 700px); */
}

.overtime-table td:nth-child(2),
.overtime-table th:nth-child(2) {
    width: 140px;
    min-width: 140px;
}

.overtime-table td:nth-child(3),
.overtime-table th:nth-child(3) {
    width: 130px;
    min-width: 130px;
}

.overtime-table td:nth-child(4),
.overtime-table th:nth-child(4) {
    width: 150px;
    min-width: 150px;
}


.first-t td:nth-child(1),
.first-t th:nth-child(1) {
    width: 330px;
}

@media (max-width: 1440px) and (min-width: 1300px) {
    .h-454 {
        /* min-height: calc(65vh + 16px); */
        min-height: calc(100vh - 304px);
    }

    .fs-16 {
        font-size: 15px !important;
    }
}

@media (max-width: 1299px) and (min-width: 1024px) {

    .fs-16 {
        font-size: 14px !important;
    }
}

@media (max-width: 1023px) and (min-width: 300px) {

    .fs-16 {
        font-size: 14px !important;
    }
}

@media (max-width: 767px) and (min-width: 300px) {

    .h-200 {
        height: auto !important;
    }
}


.fs-16 {
    font-size: 17px;
}

.width-140 {
    width: 150px;
    display: inline-block;
    margin-right: 4px;
    text-align: right;
}

.width-110 {
    width: 110px;
    display: inline-block;
    margin-right: 4px;
    text-align: right;
}

.tx {
    display: inline-block;
    width: calc(100% - 155px);
    vertical-align: text-top;
}

.txx {
    display: inline-block;
    width: calc(100% - 114px);
    vertical-align: text-top;
}

.h-200 {
    height: 170px;
}

.h-250x {
    height: 250px;
}



.h-full-200 {
    height: calc(100% - 250px);
}

img.cht-icon {
    width: 16px;
    ;
}

th.w-20,
td.w-20 {
    width: 20px !important;
    max-width: 20px !important;
}

.px-14 {
    font-size: 12px !important;
}

@media (max-width: 1299px) and (min-width: 1024px) {

    .px-14 {
        font-size: 10px !important;
    }
}

@media (max-width: 1023px) and (min-width: 300px) {

    .px-14 {
        font-size: 8px !important;
    }
}

@media (max-width: 767px) and (min-width: 300px) {

    .h-200 {
        height: auto !important;
    }
}

.f-16x {
    font-size: 18px !important;
}

.wxt-40 {
    width: 40% !important;
}

.wxt-30 {
    width: 30% !important;
}

.searchbar-cls {
    /* height: 49px; */
    font-size: 15px !important;
}

.wxt-60 {
    /* height: 49px; */
    width: 65%;
}

.critical-exceptions tr th:nth-child(2),
.critical-exceptions tr td:nth-child(2) {
    width: 160px;
}

.critical-exceptions tr th:nth-child(3),
.critical-exceptions tr td:nth-child(3) {
    width: 150px;
}

.adm-table td:nth-child(1),
.adm-table th:nth-child(1) {
    width: 500px !important;
    max-width: 500px;
}

.adm-table tr th:nth-child(3),
.adm-table tr td:nth-child(3) {
    width: 100px;
}

.adm-table tr th:nth-child(2),
.adm-table tr td:nth-child(2) {
    width: 150px;
}

.adm-table tr th:nth-child(4),
.adm-table tr td:nth-child(4) {
    width: 100px;
}

.adm-table tr th:nth-child(5),
.adm-table tr td:nth-child(5) {
    width: 150px;
}

.adm-table tr th:nth-child(6),
.adm-table tr td:nth-child(6) {
    width: 100px;
}

.adm-table tr th:nth-child(7),
.adm-table tr td:nth-child(7) {
    width: 150px;
}


/* .critical-exceptions-leader tr th:nth-child(1), .critical-exceptions-leader tr td:nth-child(1){width: 250px;} */
/* .critical-exceptions tr th:nth-child(1),
.critical-exceptions tr td:nth-child(1) {
    width: 250px;
} */