.h-690 {
    min-height: calc(100vh - 220px);
}

.h-690x {
    min-height: calc(100vh - 300px);
}
.h-690x.new-height {
    min-height: calc(100vh - 260px);
}
@media(min-width: 1500px){
    .h-690x.new-height {
        min-height: calc(100vh - 270px);
    } 
}
.color-yellow {
    color: #d1bc07;
}
tbody.ftr.footer-w{max-width: calc(100% - 4px);}
.stoploss-table thead th:nth-child(2), .stoploss-table tbody td:nth-child(2){width: 110px;}
.stoploss-table thead th:nth-child(3), .stoploss-table tbody td:nth-child(3){width: 110px;}
.stoploss-table thead th:nth-child(4), .stoploss-table tbody td:nth-child(4){width: 110px;}
.stoploss-table thead th:nth-child(5), .stoploss-table tbody td:nth-child(5){width: 120px;}