.first-t td:nth-child(1), .first-t th:nth-child(1) {
    width: auto; 
}
.table .all-side-border th {
    /* color: #FFF; */
    /* border: 1px solid; */
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    text-align: center;
    font-size: 12px;
    padding: 5px 7px !important;
}
.scroll-table tbody {
    max-height: calc(100vh - 492px);
    /* min-height: 300px; */
}
.border-0{border: 0;}

.fix-column-width tr td:nth-child(1), .fix-column-width tr th:nth-child(1){width: 80px;}
.fix-column-width tr td:nth-child(2), .fix-column-width tr th:nth-child(2){width: 80px;}
.fix-column-width tr td:nth-child(3), .fix-column-width tr th:nth-child(3){width: 100px;}
.fix-column-width tr td:nth-child(4), .fix-column-width tr.w-93 th:nth-child(4){width: 80px;}
.min-w-1400{min-width: 1400px;}
/* .fix-column-width tr td:nth-child(4){width: 94px;}
.fix-column-width tr td:nth-child(5){width: 94px;}
.fix-column-width tr td:nth-child(6){width: 94px;}
.fix-column-width tr td:nth-child(7){width: 94px;}
.fix-column-width tr td:nth-child(8){width: 94px;}
.fix-column-width tr td:nth-child(9){width: 94px;}
.fix-column-width tr td:nth-child(10){width: 94px;}
.fix-column-width tr td:nth-child(11){width: 94px;}
.fix-column-width tr td:nth-child(12){width: 94px;}
.fix-column-width tr td:nth-child(13){width: 94px;}
.fix-column-width tr td:nth-child(14){width: 94px;}
.fix-column-width tr td:nth-child(15){width: 94px;}
.fix-column-width tr td:nth-child(16){width: 94px;}
.fix-column-width tr td:nth-child(17){width: 94px;}
.fix-column-width tr td:nth-child(18){width: 94px;} */
/* .fix-column-width tr td{min-width: 93px;}
.fix-column-width tr.w-93 th{min-width: 93px;} */
.scroll-table tbody::-webkit-scrollbar {
    width: 5px;
    width: 4px;
    height: 0px;
}
.h-6100 {
    min-height: calc(100vh - 300px);
}
.fix-column-width tbody tr td{font-size: 12px;}
.fix-column-width thead tr:nth-child(1) th{padding: 2px 5px !important;}
.fix-column-width thead tr:nth-child(2) th{padding: 2px 5px !important; line-height: 1;}
/* .costcenterscorecardtable .ms-2 { margin-left: .1rem !important;} */
.costcenterscorecardtable.table thead{    width: calc(100% - 4px);}

.table .all-new-border td {
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    text-align: center;
}

.table .all-new-border tr:nth-last-child(1) td {
    border: 1px solid #828282 !important;
}


.max-w-600 .modal-dialog {
    max-width: 600px !important;
}


.budgeted-details-table td:nth-child(1), budgeted-details-table th:nth-child(1) {
    width: 100px;
}

.budgeted-details-table .h-300-bdt {
    max-height: 400px !important;
}
.uos-arrow{position: absolute;    right: -25px;}